import { RootState } from "../store";

export const selectoOnboardingLoading = (state: RootState) => {
  return state.onboarding.loading;
};

export const selectoOnboardingSuccess = (state: RootState) => {
  return state.onboarding.succeeded;
};

export const selectoOnboardingError = (state: RootState) => {
  return state.onboarding.error;
};

export const selectoOnboardingErrorMessage = (state: RootState) => {
  return state.onboarding.errorMessage;
};

export const selectoOnboardingPassword = (state: RootState) => {
  return state.onboarding.password;
};
