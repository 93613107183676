import * as React from "react";
import Seo from "../components/seo";
import { OnboardingComponent } from "../components/Authentication/Onboarding";
import { navigate } from "gatsby-link";
import { PageProps } from "gatsby";
import { useEffect } from "react";
import { loginPath } from "../utils/auth";
import OnboardingLayout from "../components/Layout/OnboardingLayout";
import { I18n } from "react-redux-i18n";
import { ThemeProvider } from "@mui/material";
import { useCurrentTheme } from "../utils/hooks";

const Onboarding = ({ location }: PageProps) => {
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const userId = params.get("userId");
  const currentTheme = useCurrentTheme();

  useEffect(() => {
    if (!token || !userId) {
      void navigate(loginPath);
    }
  }, [token, userId]);

  return (
    <div style={{ height: "100vh" }} className={currentTheme.themeName}>
      <ThemeProvider theme={currentTheme.theme}>
        <OnboardingLayout>
          <Seo title={I18n.t("Common.seo.onboarding")} />
          <OnboardingComponent token={token} userId={userId} />
        </OnboardingLayout>
      </ThemeProvider>
    </div>

  );
};

export default Onboarding;
