import React, { FC, useEffect, useState } from "react";
import { OnboardingSetPasswordStep } from "./SetPasswordStep";
import { OnboardingSetPinStep } from "./SetPinStep";
import clsx from "clsx";
import * as styles from "../authentication.module.scss";
import { I18n } from "react-redux-i18n";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import { handleNavigateToLogin } from "../../../utils/paths";
import { useSelector } from "react-redux";
import {
  selectoOnboardingError,
  selectoOnboardingSuccess,
} from "../../../redux/selectors/onboardingSelectors";
import { useIsFirstRender } from "usehooks-ts";

interface Props {
  token: string | null;
  userId?: string | null;
}

export type OnboardingMode = "setPassword" | "setPin" | "success";

export const OnboardingComponent: FC<Props> = ({ token, userId }) => {
  const [mode, setMode] = useState<OnboardingMode>("setPassword");
  const isFirstRender = useIsFirstRender();

  const requestSucceeded = useSelector(selectoOnboardingSuccess);
  const requestError = useSelector(selectoOnboardingError);

  const onPasswordSet = () => {
    setMode("setPin");
  };

  const onboardingSuccess = (
    <div className={clsx(styles.loginForm, styles.loginFormSuccess)}>
      <h1 className={styles.header}>{I18n.t("Onboarding.successTitle")}</h1>
      <div className={styles.formSuccessMessageContainer}>
        {I18n.t("Onboarding.success")}
      </div>
      <PrimaryButton
        text={I18n.t("Buttons.backToLogin")}
        event={handleNavigateToLogin}
      />
    </div>
  );

  useEffect(() => {
    if (requestSucceeded) {
      setMode("success");
    }
  }, [requestSucceeded]);

  useEffect(() => {
    if (!requestSucceeded && requestError && !isFirstRender) {
      setMode("setPassword");
    }
  }, [requestSucceeded, requestError]);

  const renderer = () => {
    switch (mode) {
    case "setPassword":
      return <OnboardingSetPasswordStep onPasswordSet={onPasswordSet} />;
    case "setPin":
      return <OnboardingSetPinStep token={token} userId={userId} />;
    case "success":
      return onboardingSuccess;
    }
  };

  return <>{renderer()}</>;
};
