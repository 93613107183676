import * as styles from "../.././authentication.module.scss";
import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { passwordSchema } from "../../schema";
import { I18n } from "react-redux-i18n";
import { useDispatch } from "react-redux";
import {
  commonFormBase,
  muiStylesAuthentication,
} from "../../muiStylesAuthentication";
import clsx from "clsx";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { setOnboardingPassword } from "../../../../redux/reducers/onboardingReducers";
import ValidationState from "../../../Forms/ValidationState";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import { useIsCapsLockOn } from "../../../../utils/hooks";

interface Props {
  onPasswordSet: () => void;
}

export const OnboardingSetPasswordStep: FC<Props> = ({ onPasswordSet }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const capsLock = useIsCapsLockOn();
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors, isValid },
  } = useForm<{ newPassword: string }>({
    mode: "all",
    defaultValues: {
      newPassword: "",
    },
    reValidateMode: "onChange",
    resolver: yupResolver(passwordSchema),
    criteriaMode: "all",
  });

  const onSubmit = handleSubmit((data): void => {
    dispatch(setOnboardingPassword(data.newPassword));
    onPasswordSet();
  });

  useEffect(() => {
    setError("newPassword", { types: { min: "min", matches: "matches" } });
    dispatch(setOnboardingPassword(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <>
        <form onSubmit={onSubmit} className={styles.loginForm}>
          <h1 className={styles.header}>
            {I18n.t("Onboarding.setPasswordFormLabel")}
          </h1>
          <div className={styles.formMessageContainer}>
            {I18n.t("Onboarding.setNewPasswordInfo")}
          </div>
          <Controller
            name="newPassword"
            render={({ field }) => (
              <TextField
                {...register("newPassword")}
                autoComplete="off"
                variant="outlined"
                fullWidth
                label={I18n.t("FormLabels.password")}
                sx={muiStylesAuthentication.inputResetPassword}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  sx: muiStylesAuthentication.inputResetPassword,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={commonFormBase.passwordVisibility}
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
              />
            )}
            control={control}
          />

          <div
            className={clsx(
              styles.loginMessagesContainer,
              styles.resetPassword
            )}
          >
            {capsLock && (
              <div className={styles.capslockContainer}>
                <ErrorOutlineOutlinedIcon fontSize="small" />
                <h3 className={styles.h3TypographyError}>{I18n.t("Login.warning")}</h3>
              </div>
            )}
            <p className={styles.validatorContainer}>
              <ValidationState
                error={"min" in (errors?.newPassword?.types || {})}
              />
              <span className={styles.validatorText}>
                {I18n.t("FormValidation.Password.length")}
              </span>
            </p>
            <p className={styles.validatorContainer}>
              <ValidationState
                error={"matches" in (errors?.newPassword?.types || {})}
              />
              <span className={styles.validatorText}>
                {I18n.t("FormValidation.Password.characters")}
              </span>
            </p>
          </div>

          <div className={styles.formButtonsContainer}>
            <PrimaryButton
              text={I18n.t("Buttons.savePassword")}
              disabled={!isValid}
              isSubmit
            />
          </div>
        </form>
      </>
    </>
  );
};
